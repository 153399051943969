/** @jsx jsx */
import { useContext, useEffect, useState } from "react"
import { jsx, Box, Styled, Flex, Grid, Button} from 'theme-ui'

import { StoreContext } from '../../context'
import LineItem from './LineItem'

import "../../styles/modal.css"

export const CartModal = () => {

  const [originalPrice, setOriginalPrice] = useState(0)
  const { store: { checkout, showCart}, toggleCart, addDiscount} = useContext(StoreContext)

  const handleCheckout = () => {
    window.open(checkout.webUrl)
  }

  useEffect(() => {
    if (checkout.id) {
      addDiscount("PILLOWQUIZ20")
    }
  },[checkout.id])

  useEffect(() => {
    if (checkout.lineItems.length > 0) {
      let total = 0; checkout.lineItems.forEach((item) => { 
        total += (parseInt(item.variant.price) * item.quantity); 
      });
      setOriginalPrice(total)
    }
  },[checkout.lineItems])

  const line_items = checkout.lineItems.map(line_item => {
    return <LineItem key={line_item.id.toString()} line_item={line_item} />
  })

  return(
    <div>
      <div onClick={(e) => toggleCart(e, showCart)} className={showCart ? 'show-opaque': 'hidden-opaque'}></div>
      <div sx={{backgroundColor: "muted", minWidth: 'xsmall', boxShadow: 'large'}} className={showCart ? 'show-right' : 'hidden-right'}>
        <Flex sx={{backgroundColor: 'light', justifyContent: 'space-between', alignItems: "center", px: 4, py: 2, borderBottom: "1px solid #e0e0e0"}}>
          <Styled.a onClick={(e) => toggleCart(e, showCart)} sx={{fontSize: "1.5rem"}}>
            <svg style={{width: "16px"}}viewBox="0 0 16 16"><path clipRule="evenodd" d="M15.78.22a.75.75 0 0 1 0 1.06L9.06 8l6.72 6.72a.75.75 0 1 1-1.06 1.06L8 9.06l-6.72 6.72a.75.75 0 0 1-1.06-1.06L6.94 8 .22 1.28A.75.75 0 1 1 1.28.22L8 6.94 14.72.22a.75.75 0 0 1 1.06 0z" fillRule="evenodd"></path></svg>
          </Styled.a>
          <Styled.h6>Your Bag</Styled.h6>
        </Flex>
        <Grid columns={[1]} gap={4} sx={{p: 4}}>
          { line_items }
        </Grid>
        <Box sx={{position: 'fixed', left: 0, right: 0, bottom: 0, p: 4, borderTop: "1px solid #c8c7c7", backgroundColor: "white"}}>
          <Flex sx={{justifyContent: "space-between", alignItems: "center"}}>
            <Styled.h5 sx={{fontWeight: "body"}}>Subtotal</Styled.h5>
            <Flex sx={{alignItems: "center"}}>
              <span sx={{fontWeight: "body", fontSize: "16px", color: "red", mr: 4, textDecoration: "line-through"}}>${originalPrice}</span>
              <Flex sx={{fontWeight: "heading"}}>$ <span sx={{fontSize: "1.5rem"}}>{checkout.subtotalPrice}</span></Flex>
            </Flex>
          </Flex>
  
            <Button onClick={handleCheckout} disabled={checkout.lineItems.length === 0} sx={{width: "100%", my: 2}}>Check out</Button>
            <small sx={{fontSize: "10px", lineHeight: 0}}>By checking out, I agree to the Terms of Use and acknowledge that I have read the Privacy Policy. Shipping and promotions calculated in checkout.</small>

        </Box>
      </div>
    </div>
  )
}