/** @jsx jsx */
import { jsx,Flex, Styled, Button, Grid, Container } from 'theme-ui'
import { useStaticQuery, graphql, Link } from 'gatsby'
import React, { useContext} from 'react'
import reduce from 'lodash/reduce'
import Bag from "../../images/messenger-bag-black.svg"
import { StoreContext } from '../../context'
import Logo from "../../images/sleepnsnooze-logo.svg"

const useQuantity = () => {
  const { store: { checkout},} = useContext(StoreContext)
	const items = checkout ? checkout.lineItems : []
	const total = reduce(items, (acc, item) => acc + item.quantity, 0)
	return [total !== 0, total]
}

export const Header = () => {

  const { store: { showCart, showDrawer}, toggleCart, toggleDrawer} = useContext(StoreContext)

  const [hasItems, quantity] = useQuantity()


  const data = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            logo
            description
            author
            address {
              street
              city
              zip
              state
            }
            pages {
              name
              slug
            }
            socials {
              instagram
              twitter
              facebook
            },
          }
        }
      }
    `
  )

	return(
    <>
      <Flex as="nav" sx={{p: 4, justifyContent: 'center', alignItems: 'center', width:  "100%", backgroundColor: "primary", position: "sticky", top: "0", height: "40px", boxShadow: "small", zIndex: "2000"}}>
        <Styled.h6 sx={{color: "light", textTransform: "uppercase"}}>Take the Quiz and Get 20% off</Styled.h6>
      </Flex>
      <Flex as="nav" sx={{p: 4, justifyContent: 'space-between', alignItems: 'center', width:  "100%", backgroundColor: "light", position: "sticky", top: "0", boxShadow: "small", zIndex: "2000"}}>
        <div sx={{display: 'flex', position: 'relative', width: ['100%', '33%', '33%'], maxWidth: "max"}}>
          <div sx={{':hover': {color: 'primary'}}}>
            <Styled.a onClick={(e) => toggleDrawer(e, showDrawer)} sx={{display: 'block', px: 4, color: 'dark', cursor: 'pointer'}}>Shop</Styled.a>
          </div>
          {data.site.siteMetadata.pages.map((page,i) => (
            <Styled.a as={Link} key={i} to={page.slug} sx={{display: ['none', 'block', 'block'], px: 4, color: 'dark'}}>
              {page.name}
            </Styled.a>
          ))}
        </div>
        <div sx={{display: 'flex', justifyContent: ['center','center','center'], width: ['100%', '33%', '33%']}}>
          <Styled.a as={Link} to="/" sx={{px: 4,mb: "0px", pb: "0px", fontSize: "0px", lineHeight: "0", color: 'dark', textTransform: "capitalize", fontSize: 3, fontWeight: "body"}}>
            <img src={Logo} width="100%" sx={{mb: "0px", pb: "0px", width: ['75px', '100px']}} />
          </Styled.a>
        </div>
        <div sx={{display: ['flex', 'flex','flex'], justifyContent: 'flex-end', alignItems: "center", width: ['100%', '33%', '33%']}}>
          <Button as={Link} to="/sleep-quiz" variant="primary" sx={{display: ['none', 'block', 'block'], px: 4}}>
             Sleep Quiz →
          </Button>
          <Styled.a onClick={(e) => toggleCart(e, showCart)} sx={{px: 4, position: 'relative'}}>
            <span sx={{position: 'absolute', borderRadius: "100%", height: '20px', width: '20px', backgroundColor: 'secondary', color: 'primary', display: 'flex', alignItems: 'center', justifyContent: 'center'}} style={{left: "8px", top: "2px"}}>
              {quantity}
            </span>
            <img src={Bag} width="30px" />
          </Styled.a>
        </div>
      </Flex>
      <Flex as="nav" sx={{p: 4, justifyContent: 'space-between', alignItems: 'center', width:  "100%", backgroundColor: "#fafafa", boxShadow: "small", zIndex: "2000"}}>
       <Container className="marquee-parent">
         <Flex sx={{justifyContent: "center", alignItems: "center", flexWrap: "nowrap"}} className="marquee">
           <Flex sx={{width: "100%", justifyContent: "center", flexWrap: "nowrap"}}>
            <Styled.h6 sx={{fontWeight: "light", my: "0px", py: "0px", fontSize: 0, color: "#5c6bc0"}}>Free 3-day delivery.</Styled.h6>
           </Flex>
           <Flex sx={{width: "100%", justifyContent: "center", flexWrap: "nowrap"}}>
            <Styled.h6 sx={{fontWeight: "light", my: "0px", py: "0px", fontSize: 0, color: "#5c6bc0"}}>100-night risk-free trial.</Styled.h6>
           </Flex>
           <Flex sx={{width: "100%", justifyContent: "center", flexWrap: "nowrap"}}>
            <Styled.h6 sx={{fontWeight: "light", my: "0px", py: "0px", fontSize: 0, color: "#5c6bc0"}}>5-year limited warranty.</Styled.h6>
           </Flex>
         </Flex>
       </Container>
      </Flex>
    </>
	)
}
