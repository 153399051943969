/** @jsx jsx */

import { jsx, Box, Flex, Styled, Grid, Button } from 'theme-ui'
import {useStaticQuery, graphql, Link} from "gatsby"
import Logo from "../../images/sleepnsnooze-logo-white.svg"

export const Footer = () => {


  const data = useStaticQuery(
    graphql`
      query {
        collections: allShopifyCollection {
          nodes {
            title
            handle
          }
        }  
        site {
          siteMetadata {
            title
            altLogo
            description
            author
            address {
              street
              city
              zip
              state
            }
            pages {
              name
              slug
            }
            socials {
              instagram
              twitter
              facebook
            }
          }
        }
      }
    `
  )

	return(
  <Box as="footer" sx={{px: 4, pt: 6, pb: 4, backgroundColor: 'primary', color: 'light'}}>
    <Grid columns={[1, 3, 3]} sx={{width: "100%", maxWidth: 'max', mx: 'auto'}}>
      <Flex sx={{flexDirection: 'column', justifyContent: 'justify-start'}}>        
        <Styled.a as={Link} to="/" sx={{px: '2', color: 'secondary'}}>
          <img src={Logo} width="150px" />
        </Styled.a>
        <Styled.p sx={{variant: 'styles.h1.light'}}>{data.site.siteMetadata.description}</Styled.p>
      </Flex>
      <Flex>
        <Flex sx={{flexDirection: 'column', justifyContent: 'justify-start', width: "50%"}}>        
          <Styled.h6 sx={{variant: 'styles.h6.light', mt: 4, mb: 2}}>Pages</Styled.h6>
          {data.site.siteMetadata.pages.map((page,i) => (
            <Styled.a as={Link} key={i} to={page.slug}  sx={{color: "light"}}>
              {page.name}
            </Styled.a>
          ))}
        </Flex>
        <Flex sx={{flexDirection: "column"}}>
          <Styled.h6 sx={{variant: 'styles.h6.light', mt: 4, mb: 2}}>Collections</Styled.h6>
          {data.collections.nodes.map((node, i) => (
            <Styled.a as={Link} to={`/collections/${node.handle}`} key={i} sx={{color: "light"}}>
              {node.title}
            </Styled.a>
          ))}
        </Flex>

      </Flex>
      <Flex sx={{flexDirection: 'column', justifyContent: 'justify-start', width: "100%"}}>
        <Styled.h5 sx={{color: "light"}}>Join our mailing list!</Styled.h5>
        <Flex sx={{backgroundColor: "light", justifyContent: "space-between"}}>
          <input type="email" sx={{minWidth: ["150px", "100px", "200px"], border: "none", px: 4, fontSize: 2}} placeholder="email" />
          <Button variant="secondary" sx={{m: 2, minWidth: "70px", width: "100%"}}>Join List</Button>
        </Flex>        
      </Flex>
    </Grid>
    <Flex sx={{justifyContent: 'center', mt: 6}}>
      <span sx={{fontSize: "10px"}}>
        © {new Date().getFullYear()},
        {` `}
      </span>
      <Styled.a href="https://www.shycat.co" sx={{color: "light", fontSize: "10px", fontWeight: "light"}}>by Shycat, LLC | </Styled.a><Styled.a as={Link} to="/terms" sx={{color: "light", fontSize: "10px", fontWeight: "light"}}> Terms and Conditions | </Styled.a><Styled.a as={Link} to="/privacy" sx={{color: "light", fontSize: "10px", fontWeight: "light"}}> Privacy</Styled.a>
    </Flex>
  </Box>
	)
}